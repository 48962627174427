<template>
  <div class="TrabajosDestacados">
    <br />

    <v-row class="my-12">
      <v-spacer></v-spacer>
      <Busqueda :buscar="buscar" @update-buscar="buscado"></Busqueda>
      <v-spacer></v-spacer>
    </v-row>

    <br />

    <v-row>
      <div :class="columnas" v-for="item in items" :key="item.id">
        <v-row>
          <v-spacer></v-spacer>
          <v-hover v-slot="{ hover }">
            <v-card
              width="450"
              style="margin-bottom: 3rem"
              transition="scale-transition"
              origin="center center"
              :elevation="hover ? 12 : 2"
            >
              <v-img
                :src="item.imagen"
                height="250px"
                class="imagCursor"
                @click="detalles(item)"
              >
              </v-img>

              <v-card-subtitle class="text-h6 grey--text--darken-4">
                {{ item.nombre }}
              </v-card-subtitle>

              <v-card-text>
                {{ item.descripcion }}
              </v-card-text>

              <v-card-actions v-show="mostrar" hidden>
                <v-btn color="primary lighten-2" text @click="detalles(item)">
                  Detalles
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-hover>
          <v-spacer></v-spacer>
        </v-row>
      </div>
    </v-row>

    <!--Dialog que muestra los detalles-->
    <DialogDetalles
      :dialog="dialog"
      :nombreProyecto="nombreProyecto"
      :categoriaProyecto="categoriaProyecto"
      :descripcionProyecto="descripcionProyecto"
      :imagenesCarrouselDetalles="imagenesCarrouselDetalles"
      @update-cerrar="update"
    ></DialogDetalles>

    <Progress :cargando="cargando"></Progress>
  </div>
</template>
<script>
import axios from "axios";
import DialogDetalles from "@/components/DialogDetalles.vue";
import Progress from "@/components/Progress.vue";
import Busqueda from "@/components/Busqueda.vue";
export default {
  name: "TrabajosDestacados",

  components: {
    DialogDetalles,
    Progress,
    Busqueda,
  },

  mounted() {
    this.destacados();
  },

  data: () => ({
    buscar: "",
    mostrar: true,
    cargando: false,

    //Dialog
    dialog: false,
    nombreProyecto: "",
    categoriaProyecto: "",
    descripcionProyecto: "",

    items: [],
    itemsCopia: [],
    imagenesCarrouselDetalles: [],
  }),
  computed: {
    columnas() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "col-12";
        case "sm":
          return "col-12";
        case "md":
          return "col-6";
        case "lg":
          return "col-4";
        case "xl":
          return "col-4";
      }
    },
  },

  watch: {
    buscar(value) {
      this.items = this.itemsCopia.filter((item) => {
        return item.nombre.toLowerCase().includes(value.toLowerCase());
      });
    },
  },

  methods: {
    buscado(buscar) {
      this.buscar = buscar;
    },
    detalles(item) {
      this.imagenesCarrouselDetalles = [
        { imagen: "/img/noDisponible.svg", id: 1 },
      ];
      this.dialog = true;
      this.nombreProyecto = item.nombre;
      this.descripcionProyecto = item.descripcion;
      this.categoriaProyecto = item.categoria;
    },
    destacados() {
      if (this.$trabajosDestacados) {
        this.cargando = false;
        for (let index = 0; index < this.$trabajosDestacados.length; index++) {
          const element = this.$trabajosDestacados[index];
          this.items.push(element);
          this.itemsCopia.push(element);
        }
      } else {
        axios.get("/php/api.php?variable=destacados").then((response) => {
          if (response.data.items) {
            this.cargando = false;

            for (let index = 0; index < response.data.items.length; index++) {
              const element = response.data.items[index];
              this.items.push(element);
              this.itemsCopia.push(element);
            }
          } else {
            setTimeout(() => {
              this.galeria();
            }, 5000);
          }
        });
      }
    },

    update(cerrar) {
      this.dialog = cerrar;
    },
  },
};
</script>

<style>
.fondoAzulLetraBlanca {
  background-color: #002655 !important;
  color: white !important;
}
</style>