<template>
  <v-dialog :value="dialog" @input="$emit('update-cerrar')" width="90%">
    <v-card flat>
      <v-card-title class="text-h5 fondoAzulLetraBlanca">
        {{ nombreProyecto }}
        <v-spacer></v-spacer>
        <v-icon color="white" @click="cerrar"> mdi-close </v-icon>
      </v-card-title>

      <v-card-text class="pl-0">
        <v-row>
          <v-col class="pl-0" cols="12" md="9">
            <v-card flat>
              <v-carousel
                :height="height"
                interval="5000"
                cycle
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="item in imagenesCarrouselDetalles"
                  :key="item.id"
                >
                  <v-img
                    lazy-src="@/assets/PrecargaLazzy.svg"
                    :src="item.imagen"
                  >
                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="mt-12" flat>
              <h2
                style="
                  margin: 0;
                  font-family: 'Open Sans', Arial, sans-serif;
                  font-size: 18px;
                  line-height: 1.6em;
                  color: #666;
                  background-color: #ffffff;
                "
              >
                Detalles
              </h2>
              <v-divider></v-divider>
              <br />
              <h5>
                <strong style="color: #494949">Nombre del proyecto :</strong>
                {{ nombreProyecto }}
              </h5>
              <v-divider></v-divider>
              <br />
              <h5>
                <strong style="color: #494949">Categoria :</strong>
                {{ categoriaProyecto }}
              </h5>
              <v-divider></v-divider>
              <br />
              <h5>
                <strong style="color: #494949">Descripcion : </strong>
                {{ descripcionProyecto }}
              </h5>
              <v-divider></v-divider>
              <br />
              <BotonCorreo></BotonCorreo>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cerrar"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BotonCorreo from "@/components/BotonCorreo.vue";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    nombreProyecto: {
      type: String,
      default: "",
    },
    categoriaProyecto: {
      type: String,
      default: "",
    },
    descripcionProyecto: {
      type: String,
      default: "",
    },
    imagenesCarrouselDetalles: {
      type: Array,
      default: [],
    },
  },
  components: {
    //usa componentes
    BotonCorreo,
  },
  methods: {
    cerrar() {
      this.$emit("update-cerrar", false);
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 700;
        case "md":
          return 700;
        case "lg":
          return 700;
        case "xl":
          return 700;
      }
    },
  },
};
</script>

<style>
.fondoAzulLetraBlanca {
  background-color: #002655 !important;
  color: white !important;
}
</style>