<template>
  <div class="Inicio">
    <Carrucel :items="items" :btnHtml="true"></Carrucel>

    <v-row>
      <v-card
        v-for="card in cards"
        :key="card.id"
        class="mx-auto my-16 grande"
        flat
      >
        <router-link :to="card.link" style="text-decoration: none !important">
          <v-card flat>
            <v-spacer></v-spacer>
            <v-img width="250" :src="card.img"></v-img>
            <br />
            <v-spacer></v-spacer>
          </v-card>

          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="giro" fab x-large dark color="gray">
              <v-icon dark size="50px"> {{ card.icono }} </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-title>
            <v-spacer></v-spacer>
            <p class="text-center black--text">
              {{ card.texto }} <br />
              {{ card.texto2 }}
            </p>
            <v-spacer></v-spacer>
          </v-card-title>
        </router-link>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import Carrucel from "@/components/Carrucel.vue";
import axios from "axios";
export default {
  name: "Inicio",

  components: {
    Carrucel,
  },

  data: () => ({
    //
    items: [
  
      {
        src: "/img/carrucelInicio/1.png",
      },
      {
        src: "/img/carrucelInicio/2.png",
      },
            {
        src: "/img/carrucelInicio/3.png",
      },
            {
        src: "/img/carrucelInicio/4.png",
      },
      {
        src: "/img/carrucelInicio/5.png",
      },
  
    ],
    cards: [
      {
        id: 1,
        texto: "Servicio de maquinado",
        img: "/img/img-1.jpg",
        icono: "mdi-cog",
        link: "/servicioDeMaquinado",
      },
      {
        id: 2,
        texto: "Ingenieria en manejo",
        texto2: "de materiales",
        img: "/img/img-2.jpg",
        icono: "mdi-wrench",
        link: "/servicioDeImm",
      },
      {
        id: 3,
        texto: "Corte laser",
        img: "/img/img-3.jpg",
        icono: "mdi-laser-pointer",
        link: "/servicioDeCorte",
      },
    ],
  }),

  mounted() {
    //this.carrucel();
  },
  methods: {
    carrucel() {
      if (this.$imagenesCarrucel === undefined) {
        axios.get("/php/api.php?variable=carrucel").then((response) => {
          this.items = response.data.items;
          console.log(this.items);
        });
      } else {
        this.items = this.$imagenesCarrucel;
        console.log(this.items);
      }
    },
  },
};
</script>

<style>
.grande {
  background: lightgray !important;
}

.grande:hover {
  cursor: pointer;
  transform: scale(1.2, 1.2);
  transition: 0.3s ease-in-out;
  border-bottom: 5px solid #002655;
}

.grande:hover .giro {
  animation-duration: 2s;
  animation-name: rotate;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
