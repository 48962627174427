import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import ServicioDeMaquinado from '../views/ServicioDeMaquinado.vue'
import ServicioDeImm from '../views/ServicioDeImm.vue'
import servicioDeCorte from '../views/ServicioDeCorte.vue'
import Nosotros from '../views/Nosotros.vue'
import TrabajosRecientes from '../views/TrabajosRecientes.vue'
import TrabajosDestacados from '../views/TrabajosDestacados.vue'
import Componentes from '../views/Componentes.vue'
import Componentes2 from '../views/Componentes2.vue'
import Contacto from '../views/Contacto.vue'
import Catalogo from '../views/Catalogo.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/servicioDeMaquinado',
    name: 'ServicioDeMaquinado',
    component: ServicioDeMaquinado
  },
  {
    path: '/servicioDeImm',
    name: 'ServicioDeImm',
    component: ServicioDeImm
  },
  {
    path: '/servicioDeCorte',
    name: 'servicioDeCorte',
    component: servicioDeCorte
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/trabajosRecientes',
    name: 'TrabajosRecientes',
    component: TrabajosRecientes
  },
  {
    path: '/trabajosDestacados',
    name: 'TrabajosDestacados',
    component: TrabajosDestacados
  },
  {
    path: '/componentes',
    name: 'Componentes',
    component: Componentes
  },
  {
    path: '/componentes2',
    name: 'Componentes2',
    component: Componentes2
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/catalogo',
    name: 'Catalogo',
    component: Catalogo
  },
  { path: "*", component: PageNotFound }
];

setTimeout(() => {
  routes = [{
    path: '/',
    name: 'Inicio',
    component: Inicio
  },];
}, 5000);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routes
});

export default router
