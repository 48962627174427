<template>
  <v-dialog
    :value="dialog"
    @input="$emit('update-cerrar')"
    width="70%"
    height="80hv"
  >
    <v-card flat>
      <v-card-title class="text-h5 fondoAzulLetraBlanca">
        Solicitar cotización
        <v-spacer></v-spacer>
        <v-icon color="white" @click="cerrar"> mdi-close </v-icon>
      </v-card-title>

      <v-form class="mx-2 my-2" ref="form" v-model="valid" lazy-validation>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            v-model="nombre"
            dense
            label="Nombre"
            outlined
            :rules="rules"
          >
          </v-text-field>

          <v-text-field
            class="ml-2"
            v-model="correo"
            dense
            label="Correo"
            outlined
            :rules="emailRules"
          >
          </v-text-field>
        </div>
        <div>
          <v-textarea
            class="cajas"
            outlined
            v-model="mensaje"
            name="input-7-4"
            label="Mensaje"
            :rules="rules"
          >
          </v-textarea>
        </div>
      </v-form>

      <v-card
        color="grey lighten-1"
        class="mx-2 my-2 contenedor"
        min-height="250px"
      >
        <v-card
          class="mx-2 my-2"
          v-for="agregado in agregados"
          :key="agregado.nombre"
          width="250px"
          height="250px"
        >
          <v-img height="150" :src="agregado.img"></v-img>

          <h4 class="mx-2">{{ agregado.nombre }}</h4>

          <div class="d-flex">
            <span class="mx-2">cantidad</span>
            <input
              class="input_cantidad"
              type="number"
              min="0"
              v-model.number="agregado.cantidad"
            />
          </div>
        </v-card>
      </v-card>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="envioFormulario"> Solicitar </v-btn>
        <v-btn color="#F44336" text @click="cerrar"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    agregados: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    // mensaje correo
    valid: true,
    loading: false,

    //mensaje de exito
    successMessage: false,

    nombre: "",
    correo: "",
    mensaje: "",

    //Las reglas
    rules: [(v) => !!v || "Dato requerido"],
    emailRules: [
      (v) => !!v || "Dato requerido",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Correo no valido",
    ],
  }),
  components: {
    //usa componentes
  },
  methods: {
    cerrar() {
      this.$emit("update-cerrar", false);
    },

    envioFormulario() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .get(
            "/php/envioCorreo.php?email=" +
              this.correo +
              "&subject=" +
              this.nombre +
              "&message=" +
              this.mensaje
          )
          .then(function (response) {
            //console.log(response);
            if (response.status == "200") {
              app.successMessage = true;
              setTimeout(() => {
                app.successMessage = false;
                app.loading = false;
                app.correo = "";
                app.nombre = "";
                app.mensaje = "";
              }, 2500);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 300;
        case "lg":
          return 450;
        case "xl":
          return 450;
      }
    },
  },
};
</script>

<style>
.fondoAzulLetraBlanca {
  background-color: #002655 !important;
  color: white !important;
}
</style>