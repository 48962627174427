<template>
  <div class="servicioDeCorte">
    <Carrucel :items="items" :btnHtml="true"></Carrucel>

    <v-container>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="8" md="8">
          <v-card flat>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="7">
                <p class="texto2" style="text-align: justify">
                  Nosotros te ofrecemos el servicio de corte láser de última
                  tecnología en fibra óptica. <br />
                  <br />
                  Con ella te garantizamos que obtendrás cortes con una
                  excelente calidad y precisión sobre casi cualquier tipo de
                  metal.
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-img
                    style="margin-top: 2rem"
                    lazy-src="@/assets/PrecargaLazzy.svg"
                    max-height="500"
                    max-width="300"
                    src="/img/corteLaser2.png"
                  ></v-img>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
          </v-card>

          <br />

          <v-row>
            <v-spacer></v-spacer>
            <v-img
              lazy-src="@/assets/PrecargaLazzy.svg"
              :width="imagenWidth"
              :src="imagenCalidad"
            ></v-img>
            <v-spacer></v-spacer>
          </v-row>

          <br />

          <v-row>
            <v-spacer></v-spacer>
            <v-img
              style="margin-top: 2rem"
              lazy-src="@/assets/PrecargaLazzy.svg"
              max-height="500"
              max-width="300"
              src="/img/corteLaser.png"
            ></v-img>
            <v-spacer></v-spacer>
          </v-row>

          <v-row style="margin-bottom: 2rem">
            <v-spacer></v-spacer>
            <BotonCorreo></BotonCorreo>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Carrucel from "@/components/Carrucel.vue";
import BotonCorreo from "@/components/BotonCorreo.vue";
export default {
  name: "servicioDeCorte",

  components: {
    Carrucel,
    BotonCorreo,
  },

  data: () => ({
    items: [
      {
        src: "/img/carrucelCorteLaser/7.png",
      },
      {
        src: "/img/carrucelCorteLaser/9.png",
      },
    ],
  }),

  computed: {
    imagenCalidad() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "/img/calidadMedium.svg";
        case "sm":
          return "/img/calidadMedium.svg";
        case "md":
          return "/img/calidadMedium.svg";
        case "lg":
          return "/img/calidadGrande.svg";
        case "xl":
          return "/img/calidadGrande.svg";
      }
    },

    imagenWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300";
        case "sm":
          return "300";
        case "md":
          return "500";
        case "lg":
          return "800";
        case "xl":
          return "800";
      }
    },
  },
};
</script>