<template>
  <div class="Componentes2">
    <br />
    <v-row>
      <v-banner color="#002550" prominent dark width="100%">
        Componentes modulares
      </v-banner>
    </v-row>
    <br />

    <div class="d-flex justify-space-around flex-wrap mx-6">
      <div>
        <h3>Conveyors modulares.</h3>
        <p style="text-align: justify; max-width: 600px">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
          aperiam sed nostrum inventore nesciunt similique, blanditiis at
          reprehenderit? Voluptates eligendi obcaecati aliquid? Eaque illo
          perferendis atque distinctio inventore? Eos, a!
        </p>
      </div>
      <div>
        <v-img
          max-height="400"
          max-width="600"
          lazy-src="@/assets/PrecargaLazzy.svg"
          src="/img/componentes/modulares/Componentes modulares.jpg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
    <br />
    <div class="d-flex justify-space-around flex-wrap mx-6">
      <div>
        <v-img
          min-height="300"
          min-width="500"
          lazy-src="@/assets/PrecargaLazzy.svg"
          src="/img/componentes/modulares/Ejemplos.svg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
      <div>
        <h3>Soluciones de todo tipo.</h3>
        <p style="text-align: justify max-width: 600px">
          Las secciones rectas, las curvas y las pendientes se pueden combinar
          según sea necesario de.
        </p>
      </div>
    </div>
    <br />
    <div class="d-flex justify-space-around flex-wrap mx-6">
      <div>
        <h3>Conveyors modulares.</h3>
        <p style="text-align: justify max-width: 600px">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad veritatis
          perspiciatis nisi quidem corporis consectetur.
        </p>
      </div>
      <div>
        <v-img
          max-height="400"
          max-width="600"
          lazy-src="@/assets/PrecargaLazzy.svg"
          src="/img/componentes/modulares/ensamble 2.jpg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
    <br />

    <v-alert type="info" outlined border="left">
      Los componentes marca oval flex son estandar y pueden ser acoplados para
      formar n formas que se adapten a las necesidades de nuestros clientes.
    </v-alert>

    <v-stepper v-model="stepper" vertical>
      <div v-for="step in stepers" :key="step.id">
        <v-stepper-step :step="step.id" editable :complete="stepper > step.id">
          {{ step.titulo }}
        </v-stepper-step>

        <v-stepper-content class="ml-0" :step="step.id">
          <v-card color="grey lighten-1" class="mb-2">
            <div class="contenedor">
              <v-card
                class="ml-2 my-2"
                v-for="elemento in step.elementos"
                :key="elemento.id"
                width="250px"
                height="250px"
              >
                <v-img height="150" :src="elemento.img"></v-img>

                <h5 class="mx-2">{{ elemento.nombre }}</h5>

                <div class="d-flex">
                  <span class="mx-2">cantidad</span>
                  <input
                    class="input_cantidad"
                    type="number"
                    min="0"
                    @change="agregar(elemento, step.id)"
                    v-model.number="elemento.cantidad"
                  />
                </div>
              </v-card>
            </div>
          </v-card>
          <button
            class="primary mb-2 px-2 py-2 rounded white--text"
            dark
            @click="stepper = step.id + 1"
          >
            Continuar
          </button>
        </v-stepper-content>
      </div>
    </v-stepper>

    <v-card color="grey lighten-1" class="mx-2 my-2" min-height="250px">
      <v-card-title class="text-h5 fondoAzulLetraBlanca">
        Componentes agregados
      </v-card-title>

      <div class="d-flex flex-wrap">
        <v-card
          class="mx-2 my-2"
          v-for="agregado in agregados"
          :key="agregado.nombre"
          width="250px"
          height="250px"
        >
          <div class="fondoAzulLetraBlanca d-flex px-2 tituloItem">
            <h4>{{ agregado.nombre }}</h4>
            <v-spacer></v-spacer>
            <v-icon color="white" @click="quitar(agregado)"> mdi-close </v-icon>
          </div>

          <v-img
            lazy-src="/img/noDisponible.svg"
            height="150"
            :src="agregado.img"
          ></v-img>

          <div class="d-flex align-center">
            <span class="mx-2">cantidad</span>

            <input
              class="input_cantidad"
              type="number"
              min="0"
              v-model.number="agregado.cantidad"
            />
          </div>
        </v-card>
      </div>
    </v-card>

    <div class="d-flex justify-end mx-4 my-10">
      <v-btn class="mb-2" color="primary" @click="solicitar">
        Solicitar cotización
      </v-btn>
    </div>

    <!--Dialog que muestra los detalles de proyecto-->
    <DialogDetallesFull
      :dialog="dialog"
      :nombreComponente="nombreComponente"
      :imagenesDetalles="imagenesDetalles"
      @update-cerrar="update"
    ></DialogDetallesFull>

    <!--Dialog que muestra los detalles para solicitar cotizacion-->
    <DialogDetallesCotizacion
      :dialog="dialogSolicitar"
      :agregados="agregados"
      @update-cerrar="dialogSolicitar = false"
    ></DialogDetallesCotizacion>
  </div>
</template>
<script>
import DialogDetallesFull from "@/components/DialogDetallesFull.vue";
import DialogDetallesCotizacion from "@/components/DialogDetallesCotizacion.vue";
import axios from "axios";
export default {
  name: "Componentes2",

  components: {
    DialogDetallesFull,
    DialogDetallesCotizacion,
  },

  data: () => ({
    imagenesDetalles: "",
    nombreComponente: "",
    buscar: "",
    cargando: true,

    stepper: 1,
    stepers: [
      {
        id: 1,
        titulo: "",
        elementos: [],
      },
    ],
    stepers2: [],
    initCategoria: "",
    index: 0,

    agregados: [],
    idNuevo: "",
    dialogSolicitar: false,

    //Dialog detalles
    dialog: false,
  }),
  computed: {
    headers() {
      return [{ text: "Nombre", value: "nombre", align: "left cardComponent" }];
    },
  },
  mounted() {
    let miPrimeraPromise = new Promise((resolve, reject) => {
      axios.get("/php/api.php?variable=lista").then((response) => {
        response.data.items.forEach((element) => {
          this.initCategoria = element.categoria;
          //
          if (this.stepers2.find(this.buscarCategoria)) {
            this.stepers2.find(this.buscarCategoria).elementos.push({
              id: element.id,
              nombre: element.nombre,
              img: element.imagen,
              cantidad: 0,
            });
          } else {
            this.index++;
            this.stepers2.push({
              id: this.index,
              titulo: element.categoria,
              elementos: [
                {
                  id: element.id,
                  nombre: element.nombre,
                  img: element.imagen,
                  cantidad: 0,
                },
              ],
            });
          }
        });
      });
      setTimeout(function () {
        resolve("¡Éxito!"); // ¡Todo salió bien!
      }, 250);
    });
    miPrimeraPromise.then((successMessage) => {
      // succesMessage es lo que sea que pasamos en la función resolve(...) de arriba.
      console.log(this.stepers2);
      this.stepers = this.stepers2;
    });
  },
  methods: {
    agregar(item, id) {
      this.idNuevo = item.id + "," + id;
      if (this.agregados.find(this.buscarElemento)) {
        //console.log("ya existe");
        //console.log(this.agregados);
        this.agregados.find(this.buscarElemento).cantidad = item.cantidad;
      } else {
        this.agregados.push({
          id: item.id + "," + id,
          nombre: item.nombre,
          img: item.img,
          cantidad: item.cantidad,
        });
        //console.log(this.agregados);
      }
    },

    buscarElemento(item) {
      return item.id === this.idNuevo;
    },

    buscarCategoria(item) {
      return item.titulo === this.initCategoria;
    },

    solicitar() {
      //
      this.dialogSolicitar = true;
    },

    quitar(item) {
      //console.log(this.agregados.indexOf(item));
      this.removeItemFromArr(this.agregados, item);
    },

    removeItemFromArr(arr, item) {
      var i = arr.indexOf(item);

      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    detalles(item) {
      this.nombreComponente = item.nombre;
      this.dialog = true;
      this.imagenesDetalles = item.imagen;
      //console.log(this.imagenesDetalles);
    },

    update(cerrar) {
      this.dialog = cerrar;
    },
  },
};
</script>

<style>
.cardComponent {
  margin: 0 !important;
  padding: 0 !important;
  /*
  border: #002655 solid 2px !important;
  */
}

.tabla {
  margin-left: 10% !important;
  margin-right: 10% !important;
  margin-bottom: 5% !important;
  margin-top: 5% !important;
}

.titulo {
  /*
  background-color: #b8cce4 !important;
   */
  border-bottom: #002655 solid 2px !important;

  padding: 1rem !important;
}

.imag:hover {
  cursor: pointer;
}

.contenedor {
  display: flex;
  flex-wrap: wrap;
}

.input_cantidad {
  width: 5rem;
  border-bottom: 1px solid gray;
}

.input_cantidad:focus {
  outline: none;
}

.tituloItem {
  height: 3rem;
}
</style>